<template>
  <body-layout>
    <div class="actions flex" slot="actions">
      <!--       <a-button class="mr-3" @click="batchReject" v-if="selectedRowKeys.length && !isPass" v-has="'sentence:delete'"
        >否决</a-button
      >
      <a-button class="mr-3" @click="batchPass" v-if="selectedRowKeys.length && !isPass" v-has="'sentence:delete'"
        >通过</a-button
      > -->
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="'sentence:delete'"
        >删除</a-button
      >
      <!-- <a-button v-has="'sentence:add'" type="primary" @click="openAddForm">新建</a-button> -->
    </div>
    <div class="table-page-search-wrapper" v-has="'sentence:search'" slot="search">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-input v-model="searchText" style="width:300px" placeholder="请输入内容" />
          </a-col>
          <a-col>
            <a-button type="primary" icon="search" @click="searchQuery">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div v-has="'sentence:add'" style="line-height:32px;padding-bottom:16px;">
      <!--       <a-radio-group name="radioGroup" @change="changeRadio" v-model="isPass" :default-value="1">
        <a-radio :value="1">
          已通过
        </a-radio>
        <a-radio :value="0">
          未通过
        </a-radio>
      </a-radio-group> -->
    </div>
    <a-table
      ref="table"
      id="out-table"
      class="sentence-table"
      size="middle"
      :rowKey="record => record.id"
      :columns="columns.filter(c => c.dataIndex != 'action' || !isPass)"
      :customRow="clickRow"
      :dataSource="dataSource"
      :pagination="ipagination"
      :loading="loading"
      :scroll="{ x: 'max-content', y: 'calc(100vh - 353px)' }"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 45 }"
      @change="handleTableChange"
      ><span
        slot="filterIcon"
        slot-scope="filtered"
        :style="{ color: filtered ? '#108ee9' : undefined, fontSize: '16px' }"
        class="iconfont icon-shaixuan icon"
      ></span>
      <span slot="sort" slot-scope="text, record">
        {{ dataSource.findIndex(r => r.id == record.id) + 1 }}
      </span>
      <span slot="proofreaderUserName" slot-scope="text, record">
        <img v-if="record.avatar" :src="record.avatar" alt="" style="width:30px;height:30px;border-radius: 50%;" />
        <span style="margin-left:3px">{{ record.username }}</span>
      </span>
      <span slot="translationUserName" slot-scope="text, record">
        <img
          v-if="record.avatar"
          :src="record.translationUserAvatar"
          alt=""
          style="width:30px;height:30px;border-radius: 50%;"
        />
        <span style="margin-left:3px">{{ record.translationUserName }}</span>
      </span>
      <span @click.stop slot="action" v-if="!isPass" slot-scope="text, record">
        <a v-has="'sentence:delete'" @click="pass(record)">通过</a>
        <a v-has="'sentence:delete'" @click="deleteSentence(record)" style="margin-left:15px">否决</a>
      </span>
    </a-table>
    <sentence-modal ref="sentenceModal" @loadData="loadData" />
  </body-layout>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import { getAction, putAction, postAction, deleteAction } from '@/api/manage'
import sentenceModal from './modules/sentence-modal.vue'
import { checkPermission } from '@/utils/hasPermission'
export default {
  mixins: [JeecgListMixin],
  components: {
    sentenceModal
  },
  data() {
    return {
      isPass: 1,
      searchText: '',
      positionList: [],
      btnLoading: false,
      tablePage: {
        currentPage: 1,
        pageSize: 50,
        totalResult: 0
      },
      translateUsers: [],
      userIds: [],
      monthFormat: 'YYYY-MM',
      month: '',
      isEdit: false,
      isAdmin: false,
      searchUsers: [],
      userInfo: {
        roles: []
      },
      url: {
        list: '/beautiful/sentence/page',
        copyBatch: 'productDetail/copy',
        delete: '/beautiful/sentence/delete/ids',
        deleteBatch: '/productionOnline',
        syncPath: 'productDetail/sync'
      },
      color: '',
      columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          scopedSlots: { customRender: 'sort' },
          width: 50
        },
        /*         {
          title: '部门',
          dataIndex: 'departmentName',
          scopedSlots: { customRender: 'departmentName', filterIcon: 'filterIcon' },
          width: 200,
          filters: [{ text: '无数据', value: 0, children: [] }]
        }, */
        {
          title: '原文',
          dataIndex: 'originalText',
          scopedSlots: { customRender: 'originalText' },
          width: 240
        },
        {
          title: '译文',
          dataIndex: 'translateText',
          scopedSlots: { customRender: 'translateText' },
          width: 240
        },
        {
          title: '平台',
          dataIndex: 'platFormName',
          scopedSlots: { customRender: 'platFormName', filterIcon: 'filterIcon' },
          width: 70,
          filters: [{ text: '无数据', value: 0, children: [] }]
        },
        {
          title: '作品名称',
          dataIndex: 'productionName',
          scopedSlots: { customRender: 'productionName' },
          width: 120
        },
        {
          title: '章节序号',
          dataIndex: 'chapterOrder',
          scopedSlots: { customRender: 'chapterOrder' },
          width: 70
        },
        /*         {
          title: '翻译人员',
          dataIndex: 'translationUserName',
          scopedSlots: { customRender: 'translationUserName' },
          width: 150
        }, */
        {
          title: '阶段',
          dataIndex: 'belongStage',
          scopedSlots: { customRender: 'belongStage', filterIcon: 'filterIcon' },
          filters: [{ text: '无数据', value: 0, children: [] }],
          width: 70
        },
        {
          title: '人员',
          dataIndex: 'proofreaderUserName',
          scopedSlots: { customRender: 'proofreaderUserName', filterIcon: 'filterIcon' },
          width: 100,
          filters: [{ text: '无数据', value: 0, children: [] }]
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 120
        }
      ],
      departmentIds: [],
      platformIds: [],
      userIds: [],
      belongStages: [],
      filter: {
        departments: [],
        platforms: [],
        stages: [],
        users: []
      },
      clickRow: (record, index) => ({
        on: {
          click: () => {
            /* this.openDetail(record) */
          }
        }
      })
    }
  },
  methods: {
    batchPass() {
      this.loadding = true
      this.selectionRows.forEach(item => (item.isPass = 1))
      postAction('/beautiful/sentence/edit/batch', this.selectionRows)
        .then(res => {
          if (res.success) {
            this.$message.success('已通过')
            this.loadData()
          } else {
            this.$message.warning(res.message || res.msg)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    batchReject() {
      this.loadding = true
      deleteAction('/beautiful/sentence/delete/' + this.selectionRows.map(row => row.id).join(','))
        .then(res => {
          if (res.success) {
            this.$message.success('已否决')
            this.loadData()
          } else {
            this.$message.warning(res.message || res.msg)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    changeRadio() {
      this.tablePage = {
        currentPage: 1,
        pageSize: 50,
        totalResult: 0
      }
      this.ipagination.current = 1
      this.ipagination.pageSize = 50
      this.loadData()
    },
    handleTableChange(pagination, filters, sorter) {
      //分页、排序、筛选变化时触发
      //TODO 筛选
      this.savePageSize2LS(this.url.list, pagination.pageSize)
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = 'ascend' == sorter.order ? 'asc' : 'desc'
      }
      this.ipagination = pagination
      this.departmentIds = filters.departmentName
      this.platformIds = filters.platFormName
      this.userIds = filters.proofreaderUserName
      this.belongStages = filters.belongStage
      this.loadData()
    },
    checkPermission() {
      return checkPermission('sentence:edit')
    },
    openDetail(record) {
      if (!this.checkPermission()) return
      this.$refs.sentenceModal.edit(record)
    },
    openAddForm() {
      this.$refs.sentenceModal.open()
    },
    loadData(arg, id, callback) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      params.current = params.pageNo
      params.size = params.pageSize
      postAction(this.url.list, {
        pageSize: params.pageSize,
        size: params.size,
        current: params.current,
        searchText: this.searchText,
        departmentIds: this.departmentIds,
        platformIds: this.platformIds,
        userIds: this.userIds,
        belongStages: this.belongStages
        /* isPass: this.isPass */
      }).then(res => {
        console.log('JeecgListMixin loadData getAction over', { res })
        if (res.success) {
          this.selectedRowKeys = []
          this.selectionRows = []
          //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------

          // 字体预览需要往head里插入css样式
          if (this.isInserstCss) {
            batchStyleInsertHeader(res.data.records || res.data)
            setTimeout(() => {
              this.dataSource = res.data.records || res.data
              if (res.data.total) {
                this.ipagination.total = res.data.total - 0
              } else {
                this.ipagination.total = 0
              }
              this.$emit('loadData', this.dataSource)
            }, 0)
          } else {
            this.dataSource = res.data.records || res.data
            if (res.data.total) {
              this.ipagination.total = res.data.total - 0
            } else {
              this.ipagination.total = 0
            }
            this.$emit('loadData', this.dataSource)
          }
          postAction('/beautiful/sentence/getDepartmentSearch', {
            pageSize: -1,
            size: -1,
            current: 1,
            searchText: this.searchText
          }).then(res => {
            this.filter.departments = []
            if (res.code == 200) {
              this.filter.departments = res.data.map(item => {
                return { text: item.departmentName, value: item.departmentId }
              })
            } else {
              this.$message.error
            }
            /* this.columns[1].filters = this.filter.departments */
          })
          postAction('/beautiful/sentence/getPlatformSearch', {
            pageSize: -1,
            size: -1,
            current: 1,
            searchText: this.searchText
          }).then(res => {
            this.filter.platforms = []
            if (res.code == 200) {
              if (res.data && res.data.length) {
                this.filter.platforms = res.data.map(item => {
                  return { text: item.platformName, value: item.platformId }
                })
              }
            } else {
              this.$message.error(res.msg)
            }
            this.columns[3].filters = this.filter.platforms
          })
          postAction('/beautiful/sentence/getUserSearch', {
            pageSize: -1,
            size: -1,
            current: 1,
            searchText: this.searchText
          }).then(res => {
            this.filter.users = []
            if (res.code == 200) {
              if (res.data && res.data.length) {
                this.filter.users = res.data.map(item => {
                  return { text: item.username, value: item.userId }
                })
              }
            } else {
              this.$message.error(res.msg)
            }
            this.columns[7].filters = this.filter.users
          })
          postAction('/beautiful/sentence/getBelongStageSearch', {
            pageSize: -1,
            size: -1,
            current: 1,
            searchText: this.searchText
          }).then(res => {
            this.filter.stages = []
            if (res.code == 200) {
              if (res.data && res.data.length) {
                this.filter.stages = res.data.map(item => {
                  return { text: item, value: item }
                })
              }
            } else {
              this.$message.error(res.msg)
            }
            this.columns[6].filters = this.filter.stages
          })
        }
        if (res.code === 510) {
          this.$message.warning(res.message)
        }
        this.loading = false
      })
    },
    deleteSentence(record) {
      this.loadding = true
      deleteAction('/beautiful/sentence/delete/' + record.id)
        .then(res => {
          if (res.success) {
            this.$message.success('已否决')
            this.loadData()
          } else {
            this.$message.warning(res.message || res.msg)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    batchDel: function() {
      let data = []
      let that = this
      if (!this.selectionRows.length) {
        this.$message.warning('请选择至少一条数据！')
        return
      }
      this.selectionRows?.forEach(item => {
        data.push(JSON.parse(JSON.stringify(item)).id)
      })
      data = data.join(',')
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中数据?',
        onOk: function() {
          that.loading = true
          deleteAction('/beautiful/sentence/delete/' + data)
            .then(res => {
              if (res.success) {
                that.$message.success(res.message || res.msg ? '删除成功' : '')
                that.loadData()
              } else {
                that.$message.warning(res.message || res.msg)
              }
            })
            .finally(() => {
              that.loading = false
            })
        }
      })
    },
    async pass(record) {
      const res = await postAction('/beautiful/sentence/edit', {
        avatar: record.avatar,
        id: record.id,
        chapterId: record.chapterId,
        productionId: record.productionId,
        productionName: record.productionName,
        originalText: record.originalText,
        translateText: record.translateText,
        userId: record.userId,
        username: record.username,
        isPass: 1
      })
      if (res.code == 200) {
        this.$message.success('已通过')
        this.loadData()
      } else {
        this.$message.error(res.msg)
      }
    },
    searchQueryForm() {}
  },
  created() {
    /*     this.userInfo = Vue.ls.get(USER_INFO)
    this.isAdmin = false
    if (this.userInfo.roles) {
      this.userInfo.roles.forEach(one => {
        if (one.roleCode == 'admin' || one.roleCode == 'boss' || one.roleCode == 'tongchou' || one.roleCode == 'hr') {
          this.isAdmin = true
        }
      })
    } */
  }
}
</script>

<style lang="less" scoped>
.icon-shaixuan:before {
  position: relative;
  top: 8px;
}
.red {
  color: red;
}
/deep/ .ant-calendar-picker-input::-webkit-input-placeholder {
  font-size: 14px !important;
}
/deep/ .ant-calendar-picker-input::-moz-input-placeholder {
  font-size: 14px !important;
}

/deep/ .ant-calendar-picker-input::-ms-input-placeholder {
  font-size: 14px !important ;
}
</style>
