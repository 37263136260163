var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "sentence:delete",
                      expression: "'sentence:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "has",
              rawName: "v-has",
              value: "sentence:search",
              expression: "'sentence:search'",
            },
          ],
          staticClass: "table-page-search-wrapper",
          attrs: { slot: "search" },
          slot: "search",
        },
        [
          _c(
            "a-form",
            {
              attrs: { layout: "inline" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.searchQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: 16 } },
                [
                  _c(
                    "a-col",
                    [
                      _c("a-input", {
                        staticStyle: { width: "300px" },
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.searchText,
                          callback: function ($$v) {
                            _vm.searchText = $$v
                          },
                          expression: "searchText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.searchQuery },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", {
        directives: [
          {
            name: "has",
            rawName: "v-has",
            value: "sentence:add",
            expression: "'sentence:add'",
          },
        ],
        staticStyle: { "line-height": "32px", "padding-bottom": "16px" },
      }),
      _c("a-table", {
        ref: "table",
        staticClass: "sentence-table",
        attrs: {
          id: "out-table",
          size: "middle",
          rowKey: (record) => record.id,
          columns: _vm.columns.filter(
            (c) => c.dataIndex != "action" || !_vm.isPass
          ),
          customRow: _vm.clickRow,
          dataSource: _vm.dataSource,
          pagination: _vm.ipagination,
          loading: _vm.loading,
          scroll: { x: "max-content", y: "calc(100vh - 353px)" },
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            columnWidth: 45,
          },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u(
          [
            {
              key: "filterIcon",
              fn: function (filtered) {
                return _c("span", {
                  staticClass: "iconfont icon-shaixuan icon",
                  style: {
                    color: filtered ? "#108ee9" : undefined,
                    fontSize: "16px",
                  },
                })
              },
            },
            {
              key: "sort",
              fn: function (text, record) {
                return _c("span", {}, [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.dataSource.findIndex((r) => r.id == record.id) + 1
                      ) +
                      "\n    "
                  ),
                ])
              },
            },
            {
              key: "proofreaderUserName",
              fn: function (text, record) {
                return _c("span", {}, [
                  record.avatar
                    ? _c("img", {
                        staticStyle: {
                          width: "30px",
                          height: "30px",
                          "border-radius": "50%",
                        },
                        attrs: { src: record.avatar, alt: "" },
                      })
                    : _vm._e(),
                  _c("span", { staticStyle: { "margin-left": "3px" } }, [
                    _vm._v(_vm._s(record.username)),
                  ]),
                ])
              },
            },
            {
              key: "translationUserName",
              fn: function (text, record) {
                return _c("span", {}, [
                  record.avatar
                    ? _c("img", {
                        staticStyle: {
                          width: "30px",
                          height: "30px",
                          "border-radius": "50%",
                        },
                        attrs: { src: record.translationUserAvatar, alt: "" },
                      })
                    : _vm._e(),
                  _c("span", { staticStyle: { "margin-left": "3px" } }, [
                    _vm._v(_vm._s(record.translationUserName)),
                  ]),
                ])
              },
            },
            {
              key: "action",
              fn: function (text, record) {
                return !_vm.isPass
                  ? _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "has",
                                rawName: "v-has",
                                value: "sentence:delete",
                                expression: "'sentence:delete'",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.pass(record)
                              },
                            },
                          },
                          [_vm._v("通过")]
                        ),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "has",
                                rawName: "v-has",
                                value: "sentence:delete",
                                expression: "'sentence:delete'",
                              },
                            ],
                            staticStyle: { "margin-left": "15px" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteSentence(record)
                              },
                            },
                          },
                          [_vm._v("否决")]
                        ),
                      ]
                    )
                  : _vm._e()
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("sentence-modal", {
        ref: "sentenceModal",
        on: { loadData: _vm.loadData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }